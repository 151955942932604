<template>
  <Layout>
    <v-container>
      <v-row>
        <v-col
          xs12
          md6
        >
          <v-form>
            <v-container pa-0>
              <v-row>
                <v-col
                  cols="12"
                  class="mb-4 grey--text"
                >
                  Envíenos un correo electrónico con cualquier pregunta o consulta o si prefiere puede tambien llamarnos. Estaremos encantados de responder sus preguntas y concertar una reunión con usted.                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Nombre*"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Apellidoe*"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Email*"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Número Celular*"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Asunto"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Mensaje"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-btn
                    :block="$vuetify.breakpoint.xsOnly"
                    color="primary"
                    depressed
                    x-large
                  >
                    Enviar Formulario
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-container
            fluid
            pa-0
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-card-title>Contáctenos en</v-card-title>
                <v-card-text>
                  <div>Av. Transversal F., entre Ppal La Carlota y Av. 4</div>
                  <div>Qta. Costiera, PB. Urb. Campo Claro</div>
                  <div>Caracas - Venezuela</div>
                </v-card-text>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-card-title>Oficinas Regionales</v-card-title>
                <v-card-text>
                  <div>Panamá: <br/>+507 66796996</div>
                  <div>Venezuela: <br/>+58 212 234 6020<br/>+58 212 234 1563</div>
                  <div>
                    <a href="mailto:info@ajpyeventos.com">info@ajpyeventos.com</a>
                  </div>
                </v-card-text>
              </v-col>

              <v-col cols="12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3964.108066171737!2d-66.83508771776327!3d10.49231170528674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x8c2a584aa764858b%3A0x812c173d8e22c6bf!2sColegio%20Francia%2C%20D%20Avenida%2C%20Caracas%201071%2C%20Miranda!3m2!1d10.4918962!2d-66.8318541!5e0!3m2!1ses!2sve!4v1589589486419!5m2!1ses!2sve"
                  width="100%"
                  height="400"
                  frameborder="0"
                  style="border:0"
                  allowfullscreen
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
  export default {
    name: 'contacto',
    head() {
      return {
          title: 'Contacto',
          description: 'Consultoria, producción y organización profesional de eventos científicos y corporativos'
      }
    },

  }
</script>

<style lang="scss" scoped>

</style>
